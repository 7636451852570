<template>
  <div id="SUI" class="fr-wrapper">

    <img src="../../assets/img/logo.svg?v=5.5.1" id="logo" alt="STIHL logo">

    <div :class="$props.containerClass">
      <div :id="id" :class="$props.class">

        <div class="m-md-5 login-frame">

          <div class="login-header" v-if="title">
            <h1 class="title">{{ $props.title }}</h1>
            <div id="little-rect"></div>
          </div>

          <slot></slot>

        </div>

        <fr-footer v-if="showFooter"></fr-footer>

      </div>
    </div>

  </div>
</template>

<script lang="ts">
import FrFooter from "./FrFooter.vue";

export default {
  name: "ForgerockTemplate",
  components: {FrFooter},
  props: {
    id: String,
    title: String,
    class: {type: String, default: 'page'},
    containerClass: {type: String, default: 'container'},
    showFooter: {type: Boolean, default: true},
  }
}
</script>
