<template>
  <div :class="groupClass" class="otp-group">
    <input type="text" maxlength="1" autocomplete="off"
           v-for="(item,i) in items"
           :key="i"
           :id="id + '_' + i"
           ref="elems"
           class="form-control pincode-input-text"
           :value="item"
           @keydown.stop.prevent="updateValue($event, i)"
           @keyup.prevent="$emit('keyup',$event)"
           @mouseup="selectValue"
           @paste="pasta"
           :class="{
    'has-error':validationErrors && validationErrors.length > 0,
    'first': i===0,
    'last': i===5,
    }">

    <div>
      <div class="error text-danger" v-if="validationErrors" v-for="error in validationErrors">
        <div>{{ error.$message }}</div>
      </div>
    </div>

  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {ErrorObject} from "@vuelidate/core";

export default defineComponent({
  name: "OtpInput",
  emits: ['update:modelValue', 'keyup'],
  props: {
    id: String,
    name: String,
    modelValue: String,
    required: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    type: {type: String, default: 'text'},
    label: String,
    placeholder: String,
    validation: Object,
    autocomplete: String,
    appendIcon: {type: String, default: null},
    autofocus: {type: Boolean, default: false},
    validationErrors: Object as PropType<ErrorObject[]>,
    groupClass: {type: String, default: 'mb-3'}
  },
  data() {
    return {
      items: ['', '', '', '', '', ''],
    }
  },
  methods: {
    selectValue(e: MouseEvent) {
      (e.target as HTMLInputElement)?.select();
    },
    pasta(e: ClipboardEvent) {
      console.debug("pasta-event:", e) // TODO copy-pasta would be nice
    },
    updateValue(e: KeyboardEvent, key: number) {

      const val = e.key as string;
      console.debug(`[otp]got ${val} in ${key}`)

      if (e.key === 'Backspace') {
        this.items[key] = ''
        this.moveToLeft(key);
      } else if (e.key === 'ArrowLeft') {
        this.moveToLeft(key);
      } else if (e.key === 'ArrowRight') {
        this.moveToRight(key);
      }

      if (val < '0' || val > '9') {
        return false;
      }

      //console.log(`[otp]accepted`)
      this.items[key] = val
      this.moveToRight(key);

      //console.log(`[otp]update-value: ${this.value}`)
      this.$emit('update:modelValue', this.value)
    },
    moveToLeft(key: number) {
      if (key > 0) {
        const prev: HTMLInputElement = (this.$refs.elems as HTMLInputElement[])[key - 1];
        prev.select();
        prev.focus();
      }
    },
    moveToRight(key: number) {
      if (key < 5) {
        const next: HTMLInputElement = (this.$refs.elems as HTMLInputElement[])[key + 1];
        next.select();
        next.focus();
      }
    },
    focusInput(k: number) {
      (this.$refs.elems as HTMLInputElement[])[k].focus();
    }
  },
  computed: {
    value() {
      return this.items.join("")
    }
  },
  mounted() {
    if (this.$props.autofocus)
      this.$nextTick(() => {
        this.focusInput(0);
      });
  }
})
</script>

<style scoped>
.otp-group {
  display: inline-block;
  margin: 0 !important;
}

.pincode-input-text, .form-control.pincode-input-text {
  width: 3rem;
  float: left;
  margin-right: 1.5rem !important;
  text-align: left;
  padding-left: 1.2rem;
  padding-right: 0px !important;
  margin-top: 1rem !important;
}
</style>
