import { defineStore } from 'pinia';
import { Step } from '@/components/forgerock';
import Cookies from 'cookies-ts';

const cookies = new Cookies();

export const useLoginStore = defineStore('login', {
   state: () => ({
      step: undefined as Step | undefined,
      loginHint: undefined as string | undefined,
      goto: undefined as string | undefined,
      locale: undefined as string | undefined,
      realmPath: '/consumer', // can be "/consumer" or "/realms/root/realms/consumer"
      existingSession: false, // pre-existing session (needed for session upgrade)
      otpAuth: '', // OTPAuth code sent by FR
   }),
   getters: {
      /**
       * the application was not initialized if there's no step
       * @param state
       */
      haveStep: (state) => {
         return !!state.step;
      },
      currentStep: (state): Step => {
         if (!state.step) throw new Error('No current step');
         return state.step;
      },
   },
   actions: {

      /**
       * save relevant information from the authorize request
       * called 1x on application start
       * @param params
       */
      saveAuthorizeInfo(params: URLSearchParams) {
         console.debug(`[SUI] init app`);

         // save locale (if available)
         const locale = params.get('locale');
         if (locale) this.locale = locale;

         const gotoParam = params.get('goto');
         if (gotoParam) {
            const goto = new URL(gotoParam);

            // save LoginHint (if available)
            const loginHint = goto.searchParams.get('login_hint');
            if (loginHint) {
               console.info(`saving login_hint: ${loginHint}`);
               this.loginHint = loginHint;
            }

            // save the realm-path ("/consumer"  or "/realms/root/realms/consumer")
            const gotoPath = goto.pathname;
            if (gotoPath.startsWith('/auth/oauth2') && gotoPath.endsWith('/authorize')) {
               this.realmPath = gotoPath.substring('/auth/oauth2'.length, gotoPath.length - '/authorize'.length);
               console.debug(`[SUI] realmPath: ${this.realmPath}`);
            }
         }
      },
   },
});
