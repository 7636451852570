import {createApp} from 'vue'
import axios from "axios"
import {i18n} from "./lang"
import router from '@/routes'
import 'bootstrap/dist/js/bootstrap.bundle'

import App from './App.vue'
import './assets/scss/application.scss'
import {createPinia} from "pinia";

declare global {
    interface Window {
        APP_CONFIG: any         // app config loaded externally
        sessionChecked: boolean // true if the session was checked for validity
    }
}

axios.defaults.timeout = window.APP_CONFIG.axios.defaults.timout ?? 100000

const pinia = createPinia()

export const app = createApp(App)
    .use(i18n)
    .use(pinia)
    .use(router)
    .mount('#app')
