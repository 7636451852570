<template>
  <forgerock-template id="logout-page" container-class="container vh-100" :show-footer="false">
    <stihl-section>
      <stihl-overlay :active="true" wrap-class="vh-50">

        <div>mark updated session...</div>

      </stihl-overlay>
    </stihl-section>
  </forgerock-template>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import StihlSection from "@/components/ui/StihlSection.vue";
import ForgerockTemplate from "@/components/layout/ForgerockTemplate.vue";
import StihlOverlay from "@/components/ui/StihlOverlay.vue";
import {fillForgerockCallbacks, fr, Step} from "@/components/forgerock";
import {mapState} from "pinia";
import {useLoginStore} from "@/components/login.store";
import {AxiosResponse} from "axios";

export default defineComponent({
  name: "MarkUpdatedSessionPage",
  components: {ForgerockTemplate, StihlSection, StihlOverlay},
  computed: {
    ...mapState(useLoginStore, ['currentStep']),
  },
  methods: {
    submitStep(choice: 0 | 1) {
      const step = fillForgerockCallbacks(this.currentStep, {0: choice,});
      fr.submitStep(step).then((r: AxiosResponse<Step>) => {
        fr.forgerockStepHandler(r)
      })
    }
  },
  mounted() {
    const store = useLoginStore();
    if (store.existingSession) {
      console.debug('[SUI] marking session...');
      fr.setPropsOnSession({
        scs: 'up',
      }).then(() => {
        this.submitStep(0); // ok
      }).catch(() => {
        this.submitStep(1); // nok
      })
    } else {
      console.debug('[SUI] no existing session');
      this.submitStep(0);
    }
  }
})
</script>

<style scoped>
</style>
