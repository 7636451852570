<template>
  <forgerock-template id="oath-verify" :title="$t('SUI.page.oathVerify.title')">

    <bootstrap-alerts :alerts="alerts"/>

    <stihl-section>
      <stihl-overlay :active="state === 'loading'">

        <form @submit.prevent="login">

          <group-input id="totp"
                       :label="$t('SUI.page.oathVerify.code')"
                       group-class="form-group mt-5"
                       autocomplete="off" placeholder="XXXXXX" autofocus
                       inputmode="numeric" pattern="[0-9]{6}"
                       v-model="v$.form.otp.$model"
                       :validation-errors="v$.form.otp.$errors"
                       @keypress.enter="login">
            <template v-slot:after>
              <div class="form-text">{{ $t('SUI.page.oathVerify.info') }}</div>
            </template>
          </group-input>

        </form>

        <div class="buttons">
          <button id="continue" type="button" class="btn btn-primary" @click="login" :disabled="state === 'loading'">
            {{ $t('SUI.button.continue') }}
          </button>
          <button id="switchToEmailOTP" type="button" class="btn btn-secondary" @click="switchToEmailOTP"
                  :disabled="state === 'loading'">
            {{ $t('SUI.page.oathVerify.switchToEmailOTP') }}
          </button>
          <help-menu/>
        </div>

      </stihl-overlay>
    </stihl-section>

  </forgerock-template>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import StihlSection from '@/components/ui/StihlSection.vue';
import StihlOverlay from '@/components/ui/StihlOverlay.vue';
import OtpInput from '@/components/ui/OtpInput.vue';
import BootstrapAlerts, { Alert } from '@/components/ui/BootstrapAlerts.vue';
import { AxiosError, AxiosResponse } from 'axios';
import useVuelidate from '@vuelidate/core';
import { validOTP } from '@/components/validators';
import { helpers, required } from '@vuelidate/validators';
import ForgerockTemplate from '@/components/layout/ForgerockTemplate.vue';
import HelpMenu from '@/components/ui/HelpMenu.vue';
import { fillForgerockCallbacks, fr, Step } from '@/components/forgerock';
import { mapState } from 'pinia';
import { useLoginStore } from '@/components/login.store';
import GroupInput from '@/components/ui/GroupInput.vue';

export default defineComponent({
  name: 'OathTokenVerifyPage',
  components: { GroupInput, HelpMenu, ForgerockTemplate, StihlSection, OtpInput, BootstrapAlerts, StihlOverlay },
  data() {
    return {
      form: {
        otp: '',
      },
      state: 'ready' as 'ready' | 'loading',
      alerts: [] as Alert[],
    };
  },

  computed: {
    ...mapState(useLoginStore, ['currentStep']),

    email(): string {
      let data = this.currentStep?.callbacks?.find(c => c.type === 'MetadataCallback')?.output[0].value as any;
      //console.debug("[SUI] data:", data)
      return data?.email;
    }
  },

  methods: {
    alert(message: string, _class?: string) {
      this.alerts.push({ message, class: _class });
    },
    login() {
      this.submit('continue');
    },
    switchToEmailOTP() {
      this.submit('switch');
    },
    submit(to: 'continue' | 'switch') {
      if (this.state === 'loading') {
        console.log('wait. loading');
        return;
      }

      this.alerts = [];

      if (to === 'continue') {
        this.v$.$touch(); // trigger validation
        if (this.v$.$invalid) return console.error('validation failed', this.v$.$errors);
      }

      this.state = 'loading';

      const step = fillForgerockCallbacks(this.currentStep, {
        0: this.form.otp, // THIS MUST MATCH THE CALLBACK-ID RETURNED BY FORGEROCK!
        1: to === 'continue' ? 0 : 1,
      });

      fr.submitStep(step)
          .then((r: AxiosResponse<Step>) => {
            fr.forgerockStepHandler(r);
            // on success, it goes to the next page so the badCode error is not visible
            if (to === 'continue' && r.data.stage === 'OATH-Token-Verifier') {
              this.alert(this.$t('SUI.page.oathRegister.badCode'));
            }
          })
          .catch((e: AxiosError<Step>) => {
            this.alert(e.response?.data.message ?? this.$t('SUI.error.generic'));
          })
          .finally(() => {
            this.state = 'ready';
          });
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      form: { // must match the fields in 'this.form'
        otp: {
          required: helpers.withMessage(this.$t('SUI.validations.validOTP'), required), validOTP
        },
      }
    };
  },
});
</script>

<style scoped>
</style>
