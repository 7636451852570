<template>
  <div :id="id"
       class="alert alert-dismissible fade show" :class="a.class ? a.class : 'alert-danger'"
       role="alert"
       v-for="(a,i) in alerts"
       :key="i"
  >
    <span v-html="a.message"></span>
    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
  </div>
</template>


<script lang="ts">
import {defineComponent, PropType} from "vue";

export interface Alert {
  class?: string;
  message: string
}

export default defineComponent({
  name: 'BootstrapAlerts',
  props: {
    id: String,
    alerts: Object as PropType<Alert[]>,
  }
})
</script>