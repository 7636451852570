// Forgerock Types
// extracted from @forgerock/javascript-sdk-ui (https://github.com/ForgeRock/forgerock-javascript-sdk)

import axios, { AxiosResponse } from 'axios';
import Cookies from 'cookies-ts';
import router from '@/routes';
import { useLoginStore } from '@/components/login.store';

/**
 * Known errors that can occur during authentication.
 */
enum ErrorCode {
   BadRequest = 'BAD_REQUEST',
   Timeout = 'TIMEOUT',
   Unauthorized = 'UNAUTHORIZED',
   Unknown = 'UNKNOWN',
}

/**
 * Types of callbacks directly supported by the SDK.
 */
enum CallbackType {
   BooleanAttributeInputCallback = 'BooleanAttributeInputCallback',
   ChoiceCallback = 'ChoiceCallback',
   ConfirmationCallback = 'ConfirmationCallback',
   DeviceProfileCallback = 'DeviceProfileCallback',
   HiddenValueCallback = 'HiddenValueCallback',
   KbaCreateCallback = 'KbaCreateCallback',
   MetadataCallback = 'MetadataCallback',
   NameCallback = 'NameCallback',
   NumberAttributeInputCallback = 'NumberAttributeInputCallback',
   PasswordCallback = 'PasswordCallback',
   PollingWaitCallback = 'PollingWaitCallback',
   ReCaptchaCallback = 'ReCaptchaCallback',
   RedirectCallback = 'RedirectCallback',
   SelectIdPCallback = 'SelectIdPCallback',
   StringAttributeInputCallback = 'StringAttributeInputCallback',
   SuspendedTextOutputCallback = 'SuspendedTextOutputCallback',
   TermsAndConditionsCallback = 'TermsAndConditionsCallback',
   TextInputCallback = 'TextInputCallback',
   TextOutputCallback = 'TextOutputCallback',
   ValidatedCreatePasswordCallback = 'ValidatedCreatePasswordCallback',
   ValidatedCreateUsernameCallback = 'ValidatedCreateUsernameCallback',
}

export interface AuthError {
   code: number, // usually 401
   reason: string // usually	"Unauthorized"
   message: string // the actual error. "ALREADY_CONFIRMED"
}

/**
 * Represents the authentication tree API payload schema.
 */
interface Step {
   authId?: string;
   callbacks?: Callback[];
   code?: number;
   description?: string;
   detail?: StepDetail;
   header?: string;
   message?: string;
   ok?: string;
   realm?: string;
   reason?: string;
   stage?: string;
   status?: number;
   successUrl?: string;
   tokenId?: string;
}

/**
 * Represents details of a failure in an authentication step.
 */
interface StepDetail {
   failedPolicyRequirements?: FailedPolicyRequirement[];
   failureUrl?: string;
   result?: boolean;
}

/*
 * Represents configuration overrides used when requesting the next
 * step in an authentication tree.
 */

/*interface StepOptions extends ConfigOptions {
    query?: StringDict<string>;
}*/

/**
 * Represents failed policies for a matching property.
 */
interface FailedPolicyRequirement {
   policyRequirements: PolicyRequirement[];
   property: string;
}

/**
 * Represents a failed policy policy and failed policy params.
 */
interface PolicyRequirement {
   params?: Partial<PolicyParams>;
   policyRequirement: string;
}

interface PolicyParams {
   [key: string]: unknown;

   disallowedFields: string;
   duplicateValue: string;
   forbiddenChars: string;
   maxLength: number;
   minLength: number;
   numCaps: number;
   numNums: number;
}

/**
 * Represents the authentication tree API callback schema.
 */
interface Callback {
   _id?: number;
   input?: NameValue[];
   output: NameValue[];
   type: CallbackType;
}

/**
 * Represents a name/value pair found in an authentication tree callback.
 */
interface NameValue<T = unknown> {
   name: string;
   value: T;
}

interface AuthRequest {
   params?: URLSearchParams;
   body?: any;
}

export interface FrSessionInfo {
  username: string,
  universalId: string,
  realm: string,  //	"/consumer"
  latestAccessTime: Date,         //	"2024-03-25T14:44:13Z"
  maxIdleExpirationTime: Date     //	"2024-03-25T18:44:13Z"
  maxSessionExpirationTime: Date  //	"2024-03-25T22:44:12Z"
  properties: { AMCtxId: string }
}

/**
 * convert NameValue[] to Object,
 * example: "{name: "key1", value: "val1"}" ->  "{key1: val1}"
 */
export const toObject = (x: NameValue[]) => {
   const obj = {} as Record<string, any>;
   x.forEach(x => obj[x.name] = x.value);
   return obj;
};

export const fr = new class Forgerock {

   authenticate(req: AuthRequest): Promise<AxiosResponse<Step>> {

      const store = useLoginStore();

      // build the URL for calling AM (this works, because it's running in the same domain with AM)
      const url = new URL(location.href);
      url.pathname = `/auth/json${store.realmPath}/authenticate`;

      const service = url.searchParams.get('service');
      if (service && !(url.searchParams.has('authIndexType') && url.searchParams.has('authIndexValue'))) {
         // expand service param
         url.searchParams.append('authIndexType', 'service');
         url.searchParams.append('authIndexValue', service);
      }

      // if the entry point (auth-tree) was
      // - usa -> continue as is
      // - dealer_login (default) -> remove the authIndexType and authIndexValue
      // We need to do this, because the same redirect_uri is used for both entry points
      if (url.searchParams.get('authIndexValue') === 'usPortal' && url.searchParams.get('acr_values') !== 'usa') {
         url.searchParams.delete('authIndexType');
         url.searchParams.delete('authIndexValue');
      }

      // copy the provided parameters
      if (req.params) {
         req.params.forEach((v, k) => url.searchParams.set(k, v));
      }

      console.info(`auth-url: ${url.href}`);

      return axios.post(url.href, req.body, {
         headers: {
            'Accept': 'application/json, text/javascript, */*; q=0.01',
            'Accept-API-Version': 'protocol=1.0,resource=2.1',
            'Content-Type': 'application/json',
         },
      });
   }

   async submitStep(step: Step) {
      return fr.authenticate({
         body: {
            authId: step.authId,
            callbacks: step.callbacks,
            stage: step.stage,
         },
      });
   }

   /**
    * handles a typical Forgerock response
    * @param r response object
    */
   forgerockStepHandler(r: AxiosResponse<Step>) {
      //console.debug(`[SUI]forgerockStepHandler.start [${r.status}] body:`, r.data)

      if (!r.data) {
         console.info('[SUI]forgerockStepHandler no-data', r.data);
         return false;
      }

      if (r.data.successUrl) {
         console.info('[SUI] success-url:', r.data.successUrl);

         if (import.meta.env.VITE_DEBUG_MODE) { // for local testing
            console.warn('[SUI] DEBUG_MODE active!');

            const url = new URL(r.data.successUrl as string);
            url.protocol = 'http';
            url.host = 'localhost';
            url.port = '3000';

            console.warn(`[SUI] replaced ${r.data.successUrl} with ${url.href}`);
            location.href = url.href;
         } else {
            console.debug(`[SUI] redirecting to successUrl:${r.data.successUrl}`);
            window.location.href = r.data.successUrl as string;
         }

         return true;
      }

      if (r.data.stage) {

         const store = useLoginStore();

         // on confirmation page, we need to check if we are already logged in
         if (store.existingSession && r.data.stage === 'set-pw-page') {
            console.error(`[SUI] already logged in`);
            router.push({ path: '/error/alreadyLoggedIn' }); // move to next page
            return false;
         }

         store.step = r.data; // save step

         console.info(`[SUI] goto stage:${r.data.stage}`);
         router.push({ name: r.data.stage }); // move to next page
         return true;

      } else {
         const redirectCallback = r.data.callbacks?.find(c => c.type === 'RedirectCallback');
         if (redirectCallback) {
            //console.debug("[SUI] redirectCallback", redirectCallback)
            const output = toObject(redirectCallback.output);
            const authId = r.data.authId;

            if (output.trackingCookie && authId) {
               // before performing the redirect, if "trackingCookie" is set,
               // we save the "authId" in the sessionStore under the key specified by the "reentry" cookie
               // on return the authId will be sent in the body
               const cookies = new Cookies();
               const reentry = cookies.get('reentry');
               if (reentry) {
                  //console.debug("saving authId as ", reentry)
                  sessionStorage.setItem(reentry, authId);
               } else {
                  console.warn('[SUI] no reentry cookie');
               }
            } else {
               console.warn('[SUI] no trackingCookie to set');
            }

            if (output.redirectMethod === 'GET') {
               location.href = output.redirectUrl;
               return false;
            } else {
               throw new Error('Not supported redirect method');
            }
         }
         console.error(`[SUI] unexpected step`, r.data);
         throw new Error('[SUI] unexpected step');
      }
   }

   async logout() {
      return axios.post(`/auth/json/sessions/?_action=logout`, undefined, {
         headers: {
            'Accept-API-Version': 'resource=3.1, protocol=1.0',
            'Content-Type': 'application/json',
         },
      });
   }

   async getSessionInfo(): Promise<AxiosResponse<FrSessionInfo>> {
      return axios.post(`/auth/json/sessions/?_action=getSessionInfo`, undefined, {
         headers: {
            'Accept-API-Version': 'protocol=1.0,resource=2.0',
            'Content-Type': 'application/json',
         },
      });
   }

   async setPropsOnSession(props: Record<string, string>): Promise<AxiosResponse<Record<string, string>>> {
      console.debug('[SUI] setPropsOnSession', props);
      return axios.post(`/auth/json/consumer/sessions/?_action=updateSessionProperties`, props, {
         headers: {
            'Accept-API-Version': 'resource=3.1, protocol=1.0',
            'Content-Type': 'application/json',
         },
      });
   }
};

/**
 * feeds the values into the Forgerock callbacks.
 * the callbacks are referenced by index
 */
export const fillForgerockCallbacks = (step: Step, data: { [key: number]: any }): Step => {
   if (step?.callbacks) {
      //console.debug("[SUI] fill callbacks", step.callbacks, data)
      step.callbacks.forEach((c, i) => {
         //console.debug(`[SUI] index:${i} type:${c.type} callback.input=${c.input} data[${i}]=<${data[i]}>`)
         if (c.input && c.input[0] && data[i]) {
            c.input[0].value = data[i];
            //console.debug(`[SUI] set <${data[i]}> to ${c.type}:${i}`)
         }
      });
      //console.debug("[SUI] filled callbacks:", JSON.stringify(step.callbacks))
   } else {
      console.error('[SUI] no callbacks found');
   }

   return step;
};

export type {
   CallbackType,
   ErrorCode,
   Callback,
   FailedPolicyRequirement,
   NameValue,
   PolicyParams,
   PolicyRequirement,
   Step,
   StepDetail,
   AuthRequest,
};

