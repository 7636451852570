<template>
  <forgerock-template id="home-page" container-class="vh-100" :show-footer="false">
    <stihl-section>
      <stihl-overlay :active="true" wrap-class="vh-50">
        <span>Loading...</span>
      </stihl-overlay>
    </stihl-section>
  </forgerock-template>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import StihlSection from "@/components/ui/StihlSection.vue";
import GroupInput from "@/components/ui/GroupInput.vue";
import BootstrapAlerts from "@/components/ui/BootstrapAlerts.vue";
import ForgerockTemplate from "@/components/layout/ForgerockTemplate.vue";
import StihlOverlay from "@/components/ui/StihlOverlay.vue";
import HelpMenu from "@/components/ui/HelpMenu.vue";

export default defineComponent({
  name: "LoginPage",
  components: {HelpMenu, ForgerockTemplate, StihlSection, GroupInput, BootstrapAlerts, StihlOverlay},
  mounted() {
    console.debug('[SUI] welcome home')
  }
})
</script>

<style scoped>
</style>
