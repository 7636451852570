<template>
  <div class="position-relative h-100 stihl-overlay-wrap" :class="wrapClass">

    <slot></slot>

    <div class="position-absolute overlay stihl-overlay" v-if="active">
      <spinner v-if="showSpinner" :class="spinnerClass"/>
    </div>

  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue"
import Spinner from "./Spinner.vue"

export default defineComponent({
  name: 'StihlOverlay',
  components: {Spinner},
  props: {
    active: {type: Boolean, default: true},
    showSpinner: {type: Boolean, default: true},
    wrapClass: null,
    spinnerClass: null,
  }
})
</script>

<style scoped>
.overlay {
  inset: 0;
  z-index: 10;
  opacity: 0.85;
}
</style>