<template>
  <footer class="fr-footer">
    <div class="container">

      <div class="row footer-links">
        <span>
					<a target="_blank" :href="localizedLink(legal.imprint_url)">{{ $t('SUI.footer.imprint') }}</a>
				</span>
        <span class="line"> | </span>
        <span>
					<a target="_blank" :href="localizedLink(legal.terms_url)">{{ $t('SUI.footer.terms') }}</a>
				</span>
        <span class="line"> | </span>
        <span>
          <a target="_blank" :href="localizedLink(legal.privacy_url)">{{ $t('SUI.footer.privacy') }}</a>
        </span>
      </div>

      <div class="row footer-address">
        <span class="address">{{ $t('SUI.footer.legalEntity') }}</span>
      </div>

    </div>

  </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { localizedLink } from '@/lang';

export default defineComponent({
  name: 'FrFooter',
  data() {
    return {
      legal: window.APP_CONFIG.legal
    };
  },
  methods: {
    localizedLink,
  },
});
</script>

<style scoped>

</style>
