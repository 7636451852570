<template>
  <forgerock-template id="login-page" container-class="container login-container">
    <bootstrap-alerts :alerts="alerts"/>
    <stihl-section>
      <stihl-overlay :active="state === 'loading'">

        <form @submit.prevent="login" class="login-frame login-page">

          <div id="content" class="login-content">

            <div class="login-header">
              <h1 class="title">{{ $t('SUI.page.login.title') }}</h1>
              <div id="little-rect"></div>
            </div>

            <group-input id="username"
                         type="email"
                         :label="$t('SUI.label.email')"
                         group-class="form-group"
                         :required="true"
                         autocomplete="username"
                         autofocus
                         v-model="v$.form.username.$model"
                         :validation-errors="v$.form.username.$errors"
                         @keypress.enter="login"/>

            <group-input id="password"
                         type="password"
                         :label="$t('SUI.label.password')"
                         group-class="form-group"
                         :required="true"
                         autocomplete="current-password"
                         v-model="v$.form.password.$model"
                         :validation-errors="v$.form.password.$errors"
                         @keypress.enter="login">
              <template v-slot:after>
                  <span class="float-end pw-reset-link">
                    <a id="btnPwdReset" href="/user/ui/reset-password"
                       class="btn-link stihl-link">{{ $t('SUI.page.login.btnPwdReset') }}</a>
                </span>
              </template>
            </group-input>

            <div class="buttons">
              <button id="login" type="button" class="btn btn-primary" @click="login" :disabled="state==='loading'">
                {{ $t('SUI.button.login') }}
              </button>
            </div>

            <div class="finlRegGrp">
              <label>{{ $t('SUI.page.login.newHere') }}</label>
              <a id="btnFinReg" href="/user/ui/reset-password"
                 class="btn-link stihl-link">{{ $t('SUI.page.login.completeRegistration') }}</a>
            </div>
          </div>

          <div class="login-sep"></div>

          <div id="content2" class="login-side">
            <div class="space"></div>
            <div id="USLogin">
              <label class="form-label">Are you from the USA?</label>
              <button id="btnUSLogin" class="btn btn-secondary" @click="loginInUSANew" type="button">
                <span>Login with USA account</span> <i class="bi bi-box-arrow-up-right m-1"></i>
              </button>
            </div>
            <div class="space"></div>
            <div id="btnHelp" class="dropdown">
              <label class="form-label">{{ $t('SUI.label.needHelp') }}</label>
              <a id="help" class="btn btn-default mt-0" :href="$t('SUI.link.contact')" target="_blank">
                {{ $t('SUI.label.contact') }}
                <i class="bi bi-box-arrow-up-right m-1"></i>
              </a>
            </div>
          </div>
        </form>

      </stihl-overlay>
    </stihl-section>
  </forgerock-template>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import StihlSection from '@/components/ui/StihlSection.vue';
import GroupInput from '@/components/ui/GroupInput.vue';
import BootstrapAlerts, { Alert } from '@/components/ui/BootstrapAlerts.vue';
import { AxiosError } from 'axios';
import useVuelidate from '@vuelidate/core';
import { required } from '@/components/validators';
import ForgerockTemplate from '@/components/layout/ForgerockTemplate.vue';
import StihlOverlay from '@/components/ui/StihlOverlay.vue';
import HelpMenu from '@/components/ui/HelpMenu.vue';
import { fillForgerockCallbacks, fr, Step } from '@/components/forgerock';
import { mapState } from 'pinia';
import { useLoginStore } from '@/components/login.store';

export default defineComponent({
  name: 'LoginPage',
  components: { HelpMenu, ForgerockTemplate, StihlSection, GroupInput, BootstrapAlerts, StihlOverlay },
  data() {
    return {
      form: {
        username: '',
        password: '',
      },
      state: 'ready' as 'ready' | 'loading' | 'error',
      alerts: [] as Alert[],
    };
  },
  computed: {
    ...mapState(useLoginStore, ['currentStep', 'goto', 'loginHint', 'locale']),
  },
  methods: {
    alert(message: string, _class?: string) {
      this.alerts.push({ message, class: _class });
    },

    loginInUSANew() {
      if (this.state === 'loading') {
        console.log('wait. stil loading');
        return;
      }
      console.log('redirecting to USA...');

      this.state = 'loading';
      this.alerts = [];

      const step = fillForgerockCallbacks(this.currentStep, {
        0: 'Stihl_inc_fed',
        //1: this.form.username,
        //2: this.form.password,
      });

      fr.submitStep(step)
          .then(fr.forgerockStepHandler)
          .catch((e: AxiosError<Step>) => {
            //console.debug(`[SUI]login:error [${e.response?.status}] body:`, e.response?.data)
            this.state = 'error';
            this.alert(e.response?.data.message ?? this.$t('SUI.error.generic'));
          });
    },

    login(): any {
      if (this.state === 'loading') {
        console.log('wait. loading');
        return;
      }

      (this.v$ as any).form.$touch(); // trigger validation
      if (this.v$.$invalid) return console.error('validation failed', this.v$.$errors);

      this.state = 'loading';
      this.alerts = [];

      const step = fillForgerockCallbacks(this.currentStep, {
        0: 'localAuthentication',
        1: this.form.username,
        2: this.form.password,
      });

      fr.submitStep(step)
          .then(fr.forgerockStepHandler)
          .catch((e: AxiosError<Step>) => {
            //console.debug(`[SUI]login:error [${e.response?.status}] body:`, e.response?.data)
            this.state = 'error';
            this.alert(e.response?.data.message ?? this.$t('SUI.error.generic'));
          });
    },
  },
  mounted() {
    //console.debug(`[SUI]login.init STORE: `, this.$store.state.step)
    if (this.loginHint) this.form.username = this.loginHint;
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      form: {
        // must match the fields in 'this.form'
        username: { required, },
        password: { required, }
      }
    };
  },
});
</script>

<style scoped>
</style>
