<template>

  <section :class="$props.class">
    <h4 class="stihl-section-title" v-if="title">{{ title }}</h4>
    <slot></slot>
  </section>

</template>

<script lang="ts">
import {defineComponent} from "vue"

export default defineComponent({
  name: 'StihlSection',
  props: {
    class: {type: String, default: 'stihl-section'},
    title: String,
  }
})
</script>

<style>
.stihl-section {
  color: black;
  background-color: white;
  padding-top: 1rem;
}

.stihl-section-title {
  padding-bottom: 1rem;
}
</style>