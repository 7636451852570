import { createRouter, createWebHashHistory, RouteLocationNormalized } from 'vue-router';
import { AxiosError, AxiosResponse } from 'axios';
import { AuthError, AuthRequest, fr, FrSessionInfo } from '@/components/forgerock';
import Cookies from 'cookies-ts';
import { useLoginStore } from '@/components/login.store';
import ErrorPage from './views/ErrorPage.vue';
import HomePage from './views/HomePage.vue';
import LoginPage from './views/LoginPage.vue';
import OTPPage from './views/OTPPage.vue';
import MarkUpdatedSessionPage from './views/MarkUpdatedSessionPage.vue';
import OathTokenVerifyPage from '@/views/OathTokenVerifyPage.vue';

// this pages will be loaded dynamically on demand to accelerate the main pages
const ConfirmPage = () => import('./views/ConfirmPage.vue');
const OathRegistrationInitPage = () => import('@/views/OathRegistrationInitPage.vue');
const OathRegistrationVerifierPage = () => import('@/views/OathRegistrationVerifierPage.vue');
const InfoUsersNoRolePage = () => import('./views/InfoUsersNoRolePage.vue');
const LogoutPage = () => import('./views/LogoutPage.vue');

const routes = [
   { path: '/', name: 'home-page', component: HomePage },
   { path: '/confirm', name: 'set-pw-page', component: ConfirmPage },
   { path: '/login', name: 'login-page', component: LoginPage },
   { path: '/logout', name: 'logout-page', component: LogoutPage },
   { path: '/otp', name: 'otp-page', component: OTPPage },
   /// the OATH device registration was split in 2 because there are 2 auth-nodes that are displayed together
   { path: '/oath-register-init', name: 'OATH-Registration1', component: OathRegistrationInitPage },
   { path: '/oath-register', name: 'OATH-Registration2', component: OathRegistrationVerifierPage },
   { path: '/oath-token-verify', name: 'OATH-Token-Verifier', component: OathTokenVerifyPage },
   { path: '/auto-s-up', name: 'su-page', component: MarkUpdatedSessionPage },
   { path: '/users-no-role', name: 'users-no-role', component: InfoUsersNoRolePage },
   { path: '/error/:status([\\w_\\d]+)?', name: 'error', component: ErrorPage },
   { path: '/:pathMatch(.*)*', name: 'not-found', redirect: '/error/404' } // 404
];

const router = createRouter({
   history: createWebHashHistory(import.meta.env.BASE_URL),
   routes
});

router.beforeEach(async (to, from) => {
   console.debug(`[SUI][router] to:${to.path}`);
   const store = useLoginStore();

   if (import.meta.env.VITE_DEBUG_MODE === to.name) {
      console.debug(`[SUI][router] DEBUG PAGE ${to.name as string} !!!`);
      return true;
   }

   // check if application was initialized
   if (!store.haveStep && to.name !== 'error') {

      store.saveAuthorizeInfo(new URLSearchParams(location.search));

      return fr.getSessionInfo()
         .then((session: AxiosResponse<FrSessionInfo>) => {

            if (session.data.realm !== '/consumer') {
               console.error(`[SUI] invalid realm: ${session.data.realm}`);
               return { path: '/error/loggedInDifferentRealm' };
            }

            console.info(`[SUI] session found on realm:${session.data.realm}. starting auth...`);
            store.existingSession = true;
            return initAuthentication(to);

         })
         .catch(() => {
            console.info(`[SUI] no existing session. starting auth...`);
            return initAuthentication(to);
         });
   }

   return true;
});

const initAuthentication = async (to: RouteLocationNormalized) => {
   const req: AuthRequest = buildRequest(to);
   return fr.authenticate(req)
      .then(fr.forgerockStepHandler)
      .catch((e: AxiosError<AuthError>) => {

         const params = new URLSearchParams(window.location.search);

         // handle custom errors possible from this auth-tree
         if (params.get('service') === 'dealer_confirm') {
            const s = e.response?.data?.message;

            if (s === 'INVALID_TOKEN' || s === 'ALREADY_CONFIRMED' || s === 'MISSING_EMAIL' || s === 'MISSING_TOKEN') {
               return { path: '/error/invalidConfirmationLink' };
            }
         }

         return { path: '/error' };
      });
};

const cookies = new Cookies();

/**
 * Contact FR for the first time to get the session step, or to continue when returning from a redirect.
 * NOTE: XUI seems to send to FR all received parameters, but we only allow what we know about.
 */
const buildRequest = (route: RouteLocationNormalized) => {
   console.debug(`[SUI] Starting STIHL Login (${route.path})...`);

   const reentry = cookies.get('reentry');
   if (reentry) {
      console.debug('found reentry: ', reentry);
      cookies.remove('reentry');

      const authId = sessionStorage.getItem(reentry);
      console.debug('found authId:', authId);
      sessionStorage.removeItem(reentry);

      return {
         body: { authId }
      } as AuthRequest;
   }

   return {} as AuthRequest;
};

export default router;
