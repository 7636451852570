<template>
  <div :class="groupClass">

    <slot name="before"></slot>

    <label :for="id" class="form-label">
      {{ label }}
      <span :title="$t('SUI.label.required')" class="rq" v-if="required">*</span>
    </label>

    <input :id="id"
           :name="name"
           ref="gi"
           class="form-control"
           :class="{'has-error':validationErrors && validationErrors.length > 0}"
           :value="modelValue"
           :required="required"
           :disabled="disabled"
           :type="type"
           @input="updateValue($event)"
           @keyup.prevent="$emit('keyup',$event)"
           :inputmode="inputMode"
           :pattern="pattern"
           :placeholder="placeholder"
           :autocomplete="autocomplete"/>

    <div class="error-feedback text-danger" v-if="validationErrors" v-for="error in validationErrors">
      <div>{{ error.$message }}</div>
    </div>

    <slot name="after"></slot>

  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { ErrorObject } from '@vuelidate/core';

export default defineComponent({
  name: 'GroupInput',
  emits: ['update:modelValue', 'keyup'],
  props: {
    id: String,
    name: String,
    modelValue: String,
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    type: { type: String, default: 'text' },
    label: String,
    placeholder: String,
    validation: Object,
    autocomplete: String,
    inputMode: {
      type: String as PropType<'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal'>,
      default: 'text'
    },
    pattern: String,
    appendIcon: { type: String, default: null },
    autofocus: { type: Boolean, default: false },
    validationErrors: Object as PropType<ErrorObject[]>,
    groupClass: { type: String, default: 'mb-3' }
  },
  methods: {
    updateValue(e: Event) {
      this.$emit('update:modelValue', (<HTMLInputElement>e.target).value);
    },
    focusInput() {
      (this.$refs.gi as HTMLInputElement).focus();
    }
  },
  mounted() {
    if (this.$props.autofocus) {
      this.$nextTick(() => {
        this.focusInput();
      });
    }
  }
});
</script>

<style scoped>
</style>
