<template>
  <h5 class="mt-3 mt-md-5">{{ $t('SUI.label.needHelp') }}</h5>
  <a id="help" class="btn btn-default mt-0" :href="$t('SUI.link.contact')" target="_blank">
    {{ $t('SUI.label.contact') }}
  </a>
</template>

<script lang="ts">
import {defineComponent} from "vue"

export default defineComponent({
  name: 'HelpMenu',
})
</script>

<style scoped>
</style>